import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Modal, Button, Card } from "react-bootstrap";
import DoughnutChart from "./AccountDetail";
import Spinner from "components/Spinner";
import UploadClaim from "ops/UploadClaim";

export default class HomeScreen extends React.Component<any> {
  state = {
    loaded: false,
    plans: null,
    employee: null,
    showStartModal: false,
    showModal: false,
    modalType: null,
    modalBalance: null,
    modalStart: null,
    modalEnd: null,
    modalRunoff: null,
    modalCarry: null,
    company: null,
    HSAClaimCount: 0,
    LSAClaimCount: 0,
    isHSAModalOpen: false,
    isLSAModalOpen: false,
    accountType: null,
    policyNo: null,
    enableWellness: false,
    selectedItem: null,
    showPopup: false,
    hasAdditionalAccounts: false,
    isFlex: false,
    dynamicEmployeeStartDate: null,
  };

  componentDidMount() {
    if (!this.state.loaded) {
      this.startLoading();
    }
  }

  handleItemSelect = (item) => {
    this.setState({
        selectedItem: item,
        showPopup: true
    });
  };

  closePopup = () => {
      this.setState({
          showPopup: false
      });
  };

  async startLoading() {
    const params = new URLSearchParams(window.location.search);
    const employeeId = params.get('employeeId');
    
    if (!employeeId) {
      console.error("No employeeId found in URL parameters.");
      return;
    }
  
    try {
      const { data } = await axios.get(`/api/OpsEmployee/gethomepageinfo?employeeId=${employeeId}`);
  
      if (data) {
        this.setState({
          loaded: true,
          policyNo: data.policyNo ?? '',
          plans: data.spendingAccounts ?? [],
          isFlex: data.hasFlex ?? false,
          employee: data.employee ?? {},
          company: data.company ?? {},
          LSAClaimCount: data.lsaClaimCount ?? 0,
          HSAClaimCount: data.hsaClaimCount ?? 0,
          enableWellness: data.enableWellness ?? false,
          dynamicEmployeeStartDate: data.dynamicEmployeeStartDate ?? '',
        });
      } else {
        console.warn("Received empty data from API.");
      }
  
    } catch (error) {
      if (axios.isAxiosError(error)) {
        console.error("Axios error occurred:", error.message, error.response?.data || error.toJSON());
      } else {
        console.error("An unexpected error occurred:", error);
      }
    }
  }  

  displayHSAModal = () => {
    this.setState({ isHSAModalOpen: true });
  };

  closeHSAModal = () => {
    this.setState({ isHSAModalOpen: false });
  };

  displayLSAModal = () => {
    this.setState({ isLSAModalOpen: true });
  };

  closeLSAModal = () => {
    this.setState({ isLSAModalOpen: false });
  };

  checkShowModal = (type) => {
    if (type === "HSA") {
      this.setState({ isHSAModalOpen: true, accountType: type });
    } else if (type === "LSA") {
      this.setState({ isLSAModalOpen: true, accountType: type });
    }
  };

  renderHSAModal() {
    if (!this.state.isHSAModalOpen) return null;
    return (
      <UploadClaim
        employeeId={this.state.employee.id}
        policyId={this.state.company.id}
        type={this.state.accountType}
      />
    );
  }

  renderLSAModal() {
    if (!this.state.isLSAModalOpen) return null;
    return (
      <UploadClaim
        employeeId={this.state.employee.id}
        policyId={this.state.company.id}
        type={this.state.accountType}
      />
    );
  }
  
  renderContent() {
    
    if (!this.state.loaded) {
      return <Spinner />;
    }

    let { 
      enableWellness, 
      employee, 
      company, 
      modalCarry, 
      plans, 
      showModal, 
      modalType,
      showStartModal,
      modalStart,
      modalEnd,
      modalBalance,
      modalRunoff,
      dynamicEmployeeStartDate,
      policyNo,
      isFlex
    } = this.state;
    
    var showStartPopup = (
      <a
        href="#"
        className="tx-14 mg-l-5"
        onClick={() => {this.setState({showStartModal: true});}}
      >
        <i className="fa fa-info-circle tx-primary"></i>
      </a>
    );

    var startModal = (
      <Modal
        size="lg"
        style={{ color: "black" }}
        show={showStartModal}
        onHide={() => {
          this.setState({ showStartModal: false });
        }}
      >
        <Modal.Header style={{ borderBottom: "1px solid #adadad" }}>
          <Modal.Title>Benefit Start Date</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#E9ECEF" }}>
          <div
            className="card mg-t-20"
            style={{ boxShadow: "0 3px 8px rgba(0, 0, 0, 0.25)" }}
          >
            <div className="card-header tx-medium tx-uppercase bg-white">
              Description
            </div>
            <div className="card-body">
              <div className="mg-b-0">
                <p>
                  {this.state.dynamicEmployeeStartDate.replace(
                    "T00:00:00",
                    ""
                  )}
                </p>
                <p>
                  The date your company plan administrator has approved for your
                  benefits to begin.
                </p>
                <p>
                  You are able to claim expenses incurred after this date for
                  reimbursement if it falls within the current benefit year, or the previous year if you have expense carry forward.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#E9ECEF" }}>
          <Button
            variant="primary"
            onClick={() => {
              this.setState({ showStartModal: false });
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );

    var accounts = null;
    accounts = this.state.plans.map((data, i) => {
      let { type, label } = data;

      let accountName = "";
      if (type === "LSA") {
        accountName = enableWellness ? "Wellness Spending Account" : "Lifestyle Spending Account";
      } else if (label) {
        accountName = label;
      } else {
        accountName = "Health Spending Account";
      }

      const currentYearData = [
          {name: accountName, limit: data.currYear.limit, balance: data.currYear.available, backgroundColor: data.backgroundColor, textColor: data.textColor },
          ...data.currYearCustomCategories
      ];

      const totalLimit = currentYearData.reduce((acc, item) => acc + item.limit, 0).toFixed(2);

      const totalBalance = currentYearData.reduce((acc, item) => acc + item.balance, 0).toFixed(2);
      
      var prevYearContainer = null;
      var boxColor = "#223e7f";
      let totalPrevBalance = 0;
      if (data.prevYear != null && data.prevYear.status == "OPEN") {

        const prevYearData = [
          {name: accountName, limit: data.prevYear.limit, balance: data.prevYear.available },
          ...data.prevYearCustomCategories
        ];
  
        totalPrevBalance = prevYearData.reduce((acc, item) => acc + item.balance, 0).toFixed(2);
        
        if (data.type == "LSA") {
          boxColor = "#059454";
        }
        prevYearContainer = (
          <div className="col-sm-1 col-md-4 col-lg-4 pd-20 pd-t-30">
            <div
              className="pd-20 white mg-y-10"
              style={{
                backgroundColor: boxColor,
                width: "90%",
                borderRadius: "10px",
                height: "90%",
                color: "white",
              }}
            >
              plus
              <br />
              <span style={{fontWeight:"bold", fontSize:17}}>${totalPrevBalance}</span>
              <ul style={{listStyle: "disc", marginLeft: 20}}>
                {prevYearData.map((item, index) => (
                  <li key={index}>
                    {item.name}: ${item.balance}
                  </li>
                ))}
              </ul>
              <p>Your previous benefit year is still open in run-off. You can still make claims for receipts dated from {data.prevYear.startDate} - {data.prevYear.endDate} if you claim by {data.prevYear.runoff} at 11:59 PM MT</p>
            </div>
          </div>
        );
      }

      var dataType = null;
      if (data.type == "HSA") {
        dataType = "Health Spending Account";
      } else if (data.type == "LSA") {
        dataType = enableWellness
          ? "Wellness Spending Account"
          : "Lifestyle Spending Account";
      }

      var carryType = null;
      if (data.carryType === "CCF") {

        carryType = (
          <div className="text-left black">
            Your {dataType} has Credit Carry Forward Enabled. After the run-off closes, 
            unused credits will be added to your new benefit year for a maximum of one year. 
            This sometimes can take a few weeks to process but you can still claim normally. 
            Our team will take the credits out of the correct benefit period and the correct account type. 
            Any credits under this section that are from two years ago will not be transferred.
            <br />
            <br />
            {data.hasAdditionalAccounts ? 
              <>Note: Credit Carry Forward applies only to your standard {dataType}, 
              and does not apply to enhanced benefits. These enhanced benefits 
              renew annually and do not carry forward.
              </> : <></>}
          </div>
        );
      } else if (data.carryType === "ECF") {

        carryType = (
          <div className="text-left black">
            Your {dataType} has Expense Carry Forward Enabled. After run-off closes, 
            expenses dated from previous year are still eligible in the current year, 
            and paid out of current year funds.
            <br />
            <br />
            {data.hasAdditionalAccounts ?
              <>Note: Expense Carry Forward applies only to your standard {dataType}, 
              and does not apply to enhanced benefits. These enhanced benefits can 
              be claimed only for expenses dated within their respective benefit year.
              </>:<></>}
          </div>
        );
      } else {
        carryType = (
          <div className="text-left black">
            Your {dataType} has no Carry-Forward. After the run-off closes,
            unused credits will not transfer over to your new benefit year.
          </div>
        );
      }
      
      return (
        <>
          <div className="row pd-t-30" key={i}>
            <div className="col-sm-1 col-md-4 col-lg-4">
              <div style={{display:"flex", flexDirection:"column", alignItems: "center"}}>
                <DoughnutChart id={`doughnut-chart-${data.type}`} data={currentYearData} />
              </div>
            </div>
            {prevYearContainer}
            <div className="col-sm-1 col-md-4 col-lg-4 col-custom">
              <div className="step3">
                <div className="text-center mg-t-80">
                  {parseFloat(totalLimit) > 0 || parseFloat(totalBalance + totalPrevBalance) > 0 ? (
                  <div className="text-left pd-t-10">
                    <Link
                      className={
                        "btn btn-primary pd-x-25-force home-camera-" +
                        data.type.toLowerCase()
                      }
                      to={`/ops/employee/account?employeeId=${employee.id}&type=${data.type}`}
                      onClick={() => {
                        this.checkShowModal(data.type);
                      }}
                    >
                      Make a
                      {(enableWellness === false ||
                        data.type === "HSA") && (
                        <span style={{ textTransform: "uppercase" }}>
                          {" "}
                          {data.type}{" "}
                        </span>
                      )}
                      {enableWellness && data.type === "LSA" && (
                        <span style={{ textTransform: "uppercase" }}>
                          {" "}
                          WSA{" "}
                        </span>
                      )}
                      Claim
                    </Link>
                  </div>) : <></>}
                  <div className="text-left pd-t-10">
                    <Link
                      className={
                        "btn btn-primary-green pd-x-25-force home-camera-inv-" +
                        data.type.toLowerCase()
                      }
                      to={`eligibleexpenses?employeeId=${employee.id}&type=${data.type}`}
                    >
                      {currentYearData.length > 1 ? "Your Accounts" : "Your Account"}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="row"
            key={(i + 5) * 10}
            style={{ borderBottom: "1px solid #dee2e6", marginBottom: 20, paddingBottom:20 }}
          >
            <div className="col-sm-1 col-md-4 col-lg-4 pd-b-15">
              <div className="text-center">
                <div style={{ margin: "auto", textAlign: "center" }}>
                  <div className="h6 black pd-t-10">{dataType}</div>
                  <div className="h6 black">
                    Current year limit: ${totalLimit}
                    <a
                      href="#"
                      className="tx-14 mg-l-5"
                      onClick={() => {
                        let type =
                          data.type === "LSA" && enableWellness
                            ? "WSA"
                            : data.type;
                        this.setState({
                          showModal: true,
                          modalType: "Current Year " + type,
                          modalBalance: data.currYear.limit.toFixed(2),
                          modalStart: data.currYear.startDate,
                          modalEnd: data.currYear.endDate,
                          modalRunoff: data.currYear.runoff,
                          modalCarry: data.carryType,
                        });
                      }}
                    >
                    <i className="fa fa-info-circle tx-primary"></i>
                    </a>
                  </div>
                  <div className="h6 black pd-b-40">
                      <Link style={{"color":"#0d6efd"}} to={`limitcalculation?employeeId=${employee.id}&type=${data.type}`}>
                        Click here to see how your {enableWellness && data.type === "LSA" ? "WSA" : data.type} limit is calculated
                      </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-1 col-md-8 col-lg-8 pd-b-40">
              {carryType}
            </div>
          </div>
        </>
      );
    });

    var carryType = null;
    var carryText = null;
    if (modalCarry == "CCF") {
      carryType = "Credit Carry Forward";
      carryText = (
        <>
          <p>
            Unused credits will be added to your new benefit year for a maximum
            of one year. This sometimes can take a few weeks to process but you
            can still claim normally. Our team will take the credits out of the
            correct benefit period.
          </p>
          <p>
            Note: Any credits under this section that are from two years ago
            will not be transferred.
          </p>
        </>
      );
    } else if (modalCarry == "ECF") {
      carryType = "Expense Carry Forward";
      carryText = (
        <p>
          Unused credits will not be transfered over to your new benefit year.
          However, you have a longer period to claim receipts from your previous
          year using new benefit year credits
        </p>
      );
    }

    var infoModal = (
      <Modal
        size="lg"
        style={{ color: "black" }}
        show={showModal}
        onHide={() => {
          this.setState({ showModal: false });
        }}
      >
        <Modal.Header style={{ borderBottom: "1px solid #adadad" }}>
          <Modal.Title>{modalType}</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ backgroundColor: "#E9ECEF" }}>
          <p>
            Benefit period ({modalStart} - {modalEnd})
          </p>
          <div
            className="card mg-t-20"
            style={{ boxShadow: "0 3px 8px rgba(0, 0, 0, 0.25)" }}
          >
            <div className="card-header tx-medium tx-uppercase bg-white">
              Yearly Limit
            </div>
            <div className="card-body">
              <div className="mg-b-0">
                <p>${modalBalance}</p>
              </div>
            </div>
          </div>
          <div
            className="card mg-t-20"
            style={{ boxShadow: "0 3px 8px rgba(0, 0, 0, 0.25)" }}
          >
            <div className="card-header tx-medium tx-uppercase bg-white">
              Runoff Description
            </div>
            <div className="card-body">
              <div className="mg-b-0">
                <p>{modalRunoff} at 11:59 MT</p>
                <p>
                  The deadline to submit expenses incurred within your previous
                  benefit year. All proper documentation must be in before this
                  deadline.
                </p>
                <p>
                  We recommend claiming early, so you have time to resubmit if
                  needed as there are no extensions once the run-off has ended.
                </p>
              </div>
            </div>
          </div>
          <div
            className="card mg-t-20"
            style={{ boxShadow: "0 3px 8px rgba(0, 0, 0, 0.25)" }}
          >
            {carryText != null && (
              <div>
                <div className="card-header tx-medium tx-uppercase bg-white">
                  Account Type ({carryType})
                </div>
                <div className="card-body">
                  <div className="mg-b-0">{carryText}</div>
                </div>
              </div>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer style={{ backgroundColor: "#E9ECEF" }}>
          <Button
            variant="primary"
            onClick={() => {
              this.setState({ showModal: false });
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );

    return (
      <div>
        <Card 
          style={{
            maxWidth: 1250, 
            marginLeft: 50, 
            marginRight: 50, 
            marginBottom: 50, 
            fontSize: 17, 
            padding:20, 
            fontWeight: "bold",
            backgroundColor: '#fff',
            backgroundClip: 'border-box',
            border: '1px solid rgba(0, 0, 0, 0.125)',
            borderRadius: '0.25rem',
            boxShadow: '0 3px 8px rgba(0, 0, 0, 0.25)'
          }}>
          <div style={{marginBottom:10}}>
            The current benefit year goes from{" "}
            {plans[0].currYear.startDate} to{" "}
            {plans[0].currYear.endDate}. The run-off deadline for the
            current benefit year is {plans[0].currYear.runoff} at
            11:59 PM MT.
          </div>
          <div style={{marginBottom:10}}>
            Your benefit start date is{" "}
            {dynamicEmployeeStartDate.replace("T00:00:00", "")}.{" "}
            {showStartPopup}
          </div>
          <div>
            Under this policy (Policy#{policyNo}), you have the
            following coverage {isFlex && " as a Flex Spending Account"}:
          </div>
        </Card>
        <div
          className="br-section-wrapper mg-b-30 no-padding mg-x-50"
          style={{ maxWidth: 1250 }}
        >
          <div className="card mg-t-35">
            <div
              className="card-header tx-medium tx-black"
              style={{ backgroundColor: "#fff" }}
            >
              {company.name.toUpperCase()} SPENDING ACCOUNT
            </div>
            <div className="card-body">
              <div
                className="br-pagebody"
                style={{ maxWidth: 1250, marginTop: "0px" }}
              >
                <div>{accounts}</div>
              </div>
            </div>
          </div>
        </div>
        {infoModal}
        {startModal}
      </div>
    );
  }

  render() {
    return <>{this.renderContent()}</>;
  }
}