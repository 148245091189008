import React, { useState } from 'react';
import { Button, Form, Row, Col } from 'react-bootstrap';
import axios from "axios";
import toastr from "toastr";

const BenefitEnhancementsRequestHSA = (props) => {
    const [requests, setRequests] = useState([{ id: Date.now(), type: '', amount: '', description: '' }]);

    const isSubmitDisabled = !requests.some(
        (request) =>
            request.type.trim() !== '' &&
            request.amount.trim() !== '' &&
            request.description.trim() !== ''
    );

    const handleAddRequest = () => {
        setRequests([...requests, { id: Date.now(), type: '', amount: '', description: '' }]);
    };

    const handleChange = (id, field, value) => {
        setRequests(requests.map(req => {
            if (req.id === id) {
                const updatedReq = { ...req, [field]: value };

                if (field === 'type') {
                    updatedReq.description = getDefaultDescription(value);
                }

                return updatedReq;
            }
            return req;
        }));
    };

    const handleDeleteRequest = (id) => {
        setRequests(requests.filter(req => req.id !== id));
    };

    const getDefaultDescription = (type) => {
        switch (type) {
            case 'Mental Wellness':
                return 'Mental Wellness support';
            case 'Fertility Support':
                return 'Fertility support for families';
            case 'Orthodontic Care (Braces/Invisalign)':
                return 'Additional credits specific to orthodontic care (Braces, Invisalign)';
            case 'Laser Eye Surgery & Vision Therapy':
                return 'Laser/LASIK vision correction and Vision Therapy Sessions';
            case 'Naturopathy, Homeopathy & Dieticians':
                return 'Alternative medicine provided by Authorized Medical Practitioners';
            case 'Orthotics':
                return 'Additional credits for custom or prescribed orthotics';
            case 'Additional Massage':
                return 'Additional credits specific to massage therapy';
            case 'Additional Vision':
                return 'Additional credits for prescription eyewear and contact lenses';
            case 'Additional Physiotherapy':
                return 'Additional credits for physiotherapy';
            case 'Additional Dental':
                return 'Additional dental credits (all eligible dental expenses)';
            default:
                return '';
        }
    };


    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post("/api/BenefitCategories/sendenhancementsrequest", {
            accountType: "HSA",
            companyName: props.companyName,
            bencatName: props.bencatName,
            bencatRequests: requests,
            policyId: props.policyId
        }).then((response) => {
            if (response.data.success) {
                toastr.success("Your request has been submitted successfully!");
                setRequests([{ id: Date.now(), type: '', amount: '', description: '' }]);
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit}>
            <h1>Enhanced Benefits Request (HSA+)</h1>
            <img src="/plus-hsa.png" style={{float: "right", marginLeft: "10px"}}/>
            <Form.Text>
                <br/>
                <strong>It's easy to add Mental Wellness, Fertility Support, and more.</strong>
                <br/><br/>
                Enhanced Benefits are additional credits added on top of your existing HSA and/or LSA benefits to cover specific expense types your employees need.
                <br/>
                For example, if a category of employees needs extra physiotherapy coverage due to the demands of their work, you can add this coverage on top of their base benefits to address that specific need.
                <br/>
                These enhancements allow you to tailor your benefit credits to meet the diverse needs of your staff.
                <br/>
                Each Enhanced Benefit is category-specific, and you can request up to six per HSA and LSA.
                <br/>
                <a
                    href={"https://nhcfilestorage.blob.core.windows.net/docfiles/Plus-Enhanced-Benefits_HSA.pdf"}
                    target="_blank"
                    style={{fontStyle: 'italic', color: "#4987ef"}}
                >Click here to view the full HSA+ Enhanced Benefits Catalogue.</a>
                <br/><br/>
                <strong>Use the tool below to request your Enhanced Benefits. After clicking “Submit for Review,”
                    our team will assess your request and activate your Enhanced Benefits, or contact you if further clarification is needed.</strong>
            </Form.Text>

            <br/><br/>
            <Form.Text className="text-muted mt-2" style={{fontSize: 11, fontStyle: "italic"}}>Enhanced Benefits cannot be carried forward, and are automatically renewed annually unless removed by PA request.</Form.Text>

            {requests.map((request, index) => (
                <Row key={request.id} className="mb-3">
                    <Col md={3}>
                        <Form.Select
                            value={request.type}
                            onChange={(e) => handleChange(request.id, 'type', e.target.value)}
                        >
                            <option>Select...</option>
                            <option value="Mental Wellness">Mental Wellness</option>
                            <option value="Fertility Support">Fertility Support</option>
                            <option value="Orthodontic Care (Braces/Invisalign)">Orthodontic Care (Braces/Invisalign)</option>
                            <option value="Laser Eye Surgery & Vision Therapy">Laser Eye Surgery & Vision Therapy</option>
                            <option value="Naturopathy, Homeopathy & Dieticians">Naturopathy, Homeopathy & Dieticians</option>
                            <option value="Orthotics">Orthotics</option>
                            <option value="Additional Massage">Additional Massage</option>
                            <option value="Additional Vision">Additional Vision</option>
                            <option value="Additional Physiotherapy">Additional Physiotherapy</option>
                            <option value="Additional Dental">Additional Dental</option>
                        </Form.Select>
                    </Col>
                    <Col md={3}>
                        <Form.Control
                            type="number"
                            placeholder="Amount"
                            value={request.amount}
                            onChange={(e) => handleChange(request.id, 'amount', e.target.value)}
                        />
                    </Col>
                    <Col md={4}>
                        <Form.Control
                            type="text"
                            placeholder="Description"
                            value={request.description}
                            onChange={(e) => handleChange(request.id, 'description', e.target.value)}
                        />
                    </Col>
                    <Col md={2} className="d-flex align-items-center">
                        {requests.length < 6 && (
                            <Button variant="primary" onClick={handleAddRequest}>
                                +
                            </Button>)}
                        {requests.length > 1 && (
                            <Button variant="danger" onClick={() => handleDeleteRequest(request.id)}>
                                -
                            </Button>)}
                    </Col>
                </Row>
            ))}
            <Button style={{backgroundColor: '#223e7f'}} type="submit" disabled={isSubmitDisabled}>
                Submit for Review
            </Button>
        </Form>
    );
};

export default BenefitEnhancementsRequestHSA;
