import React from "react";
import axios from "axios";
// import {CustomLoading} from "../../components/CustomLoading";
// import {CustomDateRangePicker} from "../../components/CustomDateRangePicker";
import "../../styles/Reports.css";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import {CustomLoading} from "../../components/CustomLoading";
import {Table} from "react-bootstrap";
import CustomPagination from "../../components/CustomPagination";
import {EmptyStateTemplate} from "./Empty_state_Template";
import toastr from "toastr";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default class TeladocReportTable extends React.Component<any, any> {
    constructor(props) {
        super(props);
        const date = new Date();
        this.state = {
            loading: true,
            data: [],
            dataToExport: [],
            // fromDate: new Date(date.getFullYear(), date.getMonth(), 1),
            // toDate: new Date(),
            currentPage: 1,
            itemsPerPage: 12,
        };

        this.fetchData = this.fetchData.bind(this);
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = () => {
        axios.get("/api/Reports/getteladocinvoicesreport", {
            params: {
                policyId: this.props.policyId,
                // fromDate: this.state.fromDate,
                // toDate: this.state.toDate,
            }
        })
            .then(res => {
                if (res.data.success) {
                    this.setupData(res.data.invoices);
                } else {
                    this.setState({
                        loading: false,
                        data: [],
                        dataToExport: [],
                    });
                }
            });
    }

    setupData = (data) => {
        let dataToExport = [];
        let invoices = data;
        // const { fromDate, toDate } = this.state;
        // const formattedFromDate = fromDate.toISOString().slice(0,10);
        // const formattedToDate = toDate.toISOString().slice(0,10);

        invoices.forEach(inv => {
            dataToExport.push([
                inv.id,
                inv.invoiceDate,
                inv.invoiceNo,
                inv.policyId,
                inv.totalCertificates,
                inv.invoiceAmount,
                inv.taxAmount,
                inv.totalInvoiceAmount,
                inv.balanceAtBilling,
                inv.holdAmountAtBilling,
                inv.status,
                inv.isApproved,
                inv.isPaid
            ]);
        });

        const headerRow = ["Invoice Date", "Invoice No.", "Employees", "Total Premiums", "Total Tax", "Total Invoice", "Balance at Billing", "Amount Owing", "Status"];
        dataToExport.unshift(headerRow);

        this.setState({
            dataToExport: dataToExport,
            data: invoices,
            loading: false,
        });
    };

    setCurrentPage = (page) => {
        this.setState({currentPage: page});
    }

    toDataURL = (url, callback) => {
        const xhr = new XMLHttpRequest();
        xhr.onload = function () {
            const reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    };

    downloadInvoice = (inv) => {
        axios.get("/api/Reports/getteladocinvoice", {
            params: {
                policyId: inv.policyId,
                invoiceId: inv.id
                // fromDate: this.state.fromDate,
                // toDate: this.state.toDate,
            }
        })
            .then(res => {
                if (res.data.success) {
                    this.toDataURL('/teladocinvoiceheader.png', (headerImage) => {
                        this.toDataURL('/teladocinvoicelogo.png', (logoImage) => {
                            this.toDataURL('/teladocinvoicefooter.png', (footerImage) => {
                                this.generatePdf(headerImage, logoImage, footerImage, res.data);
                            });
                        });
                    });
                } else {
                    toastr.error("Failed to download invoice")
                }
            });
    }

    generatePdf = (headerImage, logoImage, footerImage, invoiceData) => {

        let invoiceDate = invoiceData.invoice.invoice.invoiceDate;
        let parsedDate = new Date(invoiceDate);

        let formattedInvoiceDate = parsedDate.toLocaleDateString('default', {
            month: 'long',
            year: 'numeric',
            timeZone: 'UTC'
        });

        let formattedInvoiceDebitDate = `15 ${parsedDate.toLocaleDateString('default', {
            month: 'long',
            year: 'numeric',
            timeZone: 'UTC'
        })}`;
        let totalInvoiceAmount = parseFloat(invoiceData.invoice.invoice.totalInvoiceAmount);
        let balanceAtBilling = parseFloat(invoiceData.invoice.invoice.balanceAtBilling) || 0;
        let holdAmountAtBilling = parseFloat(invoiceData.invoice.invoice.holdAmountAtBilling) || 0;
        let amountOwing = totalInvoiceAmount;
        let totalAmountOwing = 0;
        let outstandingInvoices = invoiceData.invoice.outstandingInvoices;

        let outstandingBalanceTable = null;
        if (Array.isArray(outstandingInvoices) && outstandingInvoices.length > 0) {
            outstandingInvoices.forEach(invoice => {
                amountOwing += invoice.totalInvoiceAmount;
            });

            outstandingBalanceTable = {
                table: {
                    headerRows: 1,
                    widths: ['*', '*', '*'],
                    body: [
                        [
                            {
                                text: 'Invoice No. (Outstanding)',
                                fillColor: '#444748',
                                color: '#fff',
                                alignment: 'center',
                                bold: true,
                                fontSize: 14,
                                margin: [0, 5, 0, 5]
                            },
                            {
                                text: 'Date',
                                fillColor: '#444748',
                                color: '#fff',
                                alignment: 'center',
                                bold: true,
                                fontSize: 14,
                                margin: [0, 5, 0, 5]
                            },
                            {
                                text: 'Total Invoice',
                                fillColor: '#444748',
                                color: '#fff',
                                alignment: 'center',
                                bold: true,
                                fontSize: 14,
                                margin: [0, 5, 0, 5]
                            }
                        ]
                    ]
                },
                layout: {
                    hLineColor: '#747778',
                    vLineColor: '#747778',
                },
                margin: [40, 30, 40, 0]
            };
            
            outstandingInvoices.forEach((item, i) => {
                outstandingBalanceTable.table.body.push([
                    {
                        text: item.invoiceNo,
                        alignment: 'center',
                        margin: [0, 5, 0, 5]
                    },
                    {
                        text: item.invoiceDate,
                        alignment: 'center',
                        margin: [0, 5, 0, 5]
                    },
                    {
                        text: `$${item.totalInvoiceAmount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                        })}`,
                        alignment: 'right',
                        margin: [0, 5, 0, 5]
                    }
                ]);
            });
        }

        let availableBalance = balanceAtBilling;
        totalAmountOwing = Math.max(0, amountOwing - availableBalance);

        // let holdAmountText = null;
        //
        // if (holdAmountAtBilling > 0) {
        //     holdAmountText = {
        //         text: `CHQ Hold Amount: $${holdAmountAtBilling.toLocaleString(undefined, {
        //             minimumFractionDigits: 2,
        //             maximumFractionDigits: 2
        //         })}`,
        //         fontSize: 12,
        //         margin: [40, 5, 40, 0],
        //         alignment: 'right',
        //         bold: false
        //     };
        // }

        const docDefinition = {
            pageMargins: [0, 50, 0, 110],
            content: [
                {
                    image: headerImage,
                    width: 595,
                    absolutePosition: {x: 0, y: 0},
                },
                {
                    image: logoImage,
                    width: 220,
                    absolutePosition: {x: 20, y: 25},
                },
                {

                    stack: [
                        {
                            text: [
                                {text: 'Invoice No: ', bold: true},
                                `${invoiceData.invoice.invoice.invoiceNo}`
                            ],
                        },
                        {
                            text: [
                                {text: 'Invoice Date: ', bold: true},
                                `${invoiceData.invoice.invoice.invoiceDate}`
                            ],
                        },
                        {
                            text: [
                                {text: 'Account No: ', bold: true},
                                `${invoiceData.invoice.policyHolder.bestDoctorNumber}`
                            ],
                        },
                        {
                            text: [
                                {text: 'Policy No: ', bold: true},
                                `${invoiceData.invoice.policyHolder.policyNo}`
                            ],
                        }
                    ],
                    absolutePosition: {x: 330, y: 50},
                    width: 400,
                    alignment: 'left',
                    fontSize: 13,
                },
                {
                    canvas: [
                        {
                            type: 'line',
                            x1: 0,
                            y1: 0,
                            x2: 280,
                            y2: 0,
                            lineWidth: 3,
                            lineColor: 'black',
                        }
                    ],
                    margin: [280, 90, 0, 0]
                },
                {
                    columns: [
                        {
                            stack: [
                                {text: 'INVOICE TO:', bold: true, margin: [0, 0, 0, 5]},
                                {text: `${invoiceData.invoice.policyHolder.name}`},
                                {text: `${invoiceData.invoice.policyHolder.mailingStreet1}`},
                                {text: `${invoiceData.invoice.policyHolder.mailingCity}, ${invoiceData.invoice.policyHolder.mailingProv}, ${invoiceData.invoice.policyHolder.mailingPostalCode}`}
                            ],
                            width: '50%',
                            alignment: 'left',
                            margin: [40, 40, 0, 0],
                            fontSize: 12,
                        },
                        {
                            stack: [
                                {text: 'INVOICE FROM:', bold: true, margin: [0, 0, 0, 5]},
                                {text: 'National HealthClaim'},
                                {text: '5320-7005 Fairmount Dr.'},
                                {text: 'SE Calgary, AB T2H 0K4'},
                                {text: '1-866-342-5908'}
                            ],
                            width: '50%',
                            alignment: 'left',
                            margin: [90, 40, 40, 0],
                            fontSize: 12,
                        }
                    ]
                },
                {
                    text: invoiceData.invoice.policyHolder.policyNo === "20407412"
                        ? 'Notice: Effective November 1, 2024, the Teladoc rate will increase to $23.18 per member.'
                        : 'Notice: Effective November 1, 2024, the Teladoc rate will increase to $17.38 per member.',
                    fontSize: 12,
                    color: 'red',
                    margin: [40, 30, 40, 5],
                    bold: true,
                    alignment: 'center',
                    border: [true, true, true, true],
                    fillColor: '#f9f9f9',
                    padding: [10, 5, 10, 5]
                },
                {
                    table: {
                        headerRows: 1,
                        widths: ['*', '*', '*', '*', '*'],
                        body: [
                            [
                                {
                                    text: 'Members',
                                    fillColor: '#444748',
                                    color: '#fff',
                                    alignment: 'center',
                                    bold: true,
                                    fontSize: 14,
                                    margin: [0, 5, 0, 5]
                                },
                                {
                                    text: 'Rate',
                                    fillColor: '#444748',
                                    color: '#fff',
                                    alignment: 'center',
                                    bold: true,
                                    fontSize: 14,
                                    margin: [0, 5, 0, 5]
                                },
                                {
                                    text: 'Premiums',
                                    fillColor: '#444748',
                                    color: '#fff',
                                    alignment: 'center',
                                    bold: true,
                                    fontSize: 14,
                                    margin: [0, 5, 0, 5]
                                },
                                {
                                    text: 'GST (5%)',
                                    fillColor: '#444748',
                                    color: '#fff',
                                    alignment: 'center',
                                    bold: true,
                                    fontSize: 14,
                                    margin: [0, 5, 0, 5]
                                },
                                {
                                    text: 'Total Invoice',
                                    fillColor: '#444748',
                                    color: '#fff',
                                    alignment: 'center',
                                    bold: true,
                                    fontSize: 14,
                                    margin: [0, 5, 0, 5]
                                },
                            ], [
                                {
                                    text: `${invoiceData.invoice.invoice.totalCertificates}`,
                                    alignment: 'center',
                                    margin: [0, 5, 0, 5]
                                },
                                {
                                    text: `$${invoiceData.invoice.invoiceData[0].amount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}`,
                                    alignment: 'right',
                                    margin: [0, 5, 0, 5]
                                },
                                {
                                    text: `$${invoiceData.invoice.invoice.invoiceAmount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}`,
                                    alignment: 'right',
                                    margin: [0, 5, 0, 5]
                                },
                                {
                                    text: `$${invoiceData.invoice.invoice.taxAmount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}`,
                                    alignment: 'right',
                                    margin: [0, 5, 0, 5]
                                },
                                {
                                    text: `$${invoiceData.invoice.invoice.totalInvoiceAmount.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                    })}`,
                                    alignment: 'right',
                                    margin: [0, 5, 0, 5]
                                }
                            ]
                        ]
                    },
                    layout: {
                        hLineColor: '#747778',
                        vLineColor: '#747778',
                    },
                    margin: [40, 30, 40, 0]
                },
                outstandingBalanceTable ? outstandingBalanceTable : {},
                {
                    text: `Available Balance: $${balanceAtBilling.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}`,
                    fontSize: 12,
                    margin: [40, 20, 40, 0],
                    alignment: 'right',
                    bold: false
                },
                {
                    text: `Amount Owing: $${totalAmountOwing.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    })}`,
                    fontSize: 14,
                    margin: [40, 10, 40, 20],
                    alignment: 'right',
                    bold: true
                },
                {
                    text: 'For the Teladoc (Best Doctors) - Elite Diagnostic Imaging Service, National HealthClaim Corp. collects the membership fees and GST on behalf of Teladoc Canada.\nBest Doctors Canada: GST# 85647 0109 RT0001.',
                    fontSize: 12,
                    margin: [40, 30, 40, 20]
                },
                {
                    text: [
                        {text: 'TERMS & CONDITIONS\n', bold: true, fontSize: 14},
                        {
                            text: `\n(*) If you are not set up for pre-authorized payments, please remit payment to National HealthClaim Corp during ${formattedInvoiceDate}. If you are set up for pre-authorized payments, the total due will automatically be debited to your pre-authorized payment account on or around ${formattedInvoiceDebitDate}.`,
                            fontSize: 11
                        }
                    ],
                    margin: [40, 15, 40, 15]
                },
                {
                    text: 'Member Eligibility List',
                    fontSize: 14,
                    bold: true,
                    margin: [40, 20, 40, 10],
                    pageBreak: 'before'
                },
                {
                    table: {
                        headerRows: 1,
                        widths: [40, '*', '*', '*', '*'],
                        body: [
                            [
                                {
                                    text: '',
                                    fillColor: '#444748',
                                    color: '#fff',
                                    alignment: 'center',
                                    bold: true,
                                    fontSize: 14,
                                    margin: [0, 5, 0, 5]
                                },
                                {
                                    text: 'Last Name',
                                    fillColor: '#444748',
                                    color: '#fff',
                                    alignment: 'left',
                                    bold: true,
                                    fontSize: 14,
                                    margin: [0, 5, 0, 5]
                                },
                                {
                                    text: 'First Name',
                                    fillColor: '#444748',
                                    color: '#fff',
                                    alignment: 'left',
                                    bold: true,
                                    fontSize: 14,
                                    margin: [0, 5, 0, 5]
                                },
                                {
                                    text: 'Rate',
                                    fillColor: '#444748',
                                    color: '#fff',
                                    alignment: 'center',
                                    bold: true,
                                    fontSize: 14,
                                    margin: [0, 5, 0, 5]
                                },
                                {
                                    text: 'Province',
                                    fillColor: '#444748',
                                    color: '#fff',
                                    alignment: 'center',
                                    bold: true,
                                    fontSize: 14,
                                    margin: [0, 5, 0, 5]
                                }
                            ],
                            ...invoiceData.invoice.invoiceData.map((member, index) => {
                                return [
                                    {text: `${index + 1}`, alignment: 'center', margin: [0, 5, 0, 5]},
                                    {text: member.lastName, alignment: 'left', margin: [0, 5, 0, 5]},
                                    {text: member.firstName, alignment: 'left', margin: [0, 5, 0, 5]},
                                    {
                                        text: `$${member.amount.toLocaleString(undefined, {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2
                                        })}`, alignment: 'right', margin: [0, 5, 0, 5]
                                    },
                                    {text: member.mailingProv, alignment: 'center', margin: [0, 5, 0, 5]}
                                ];
                            })
                        ]
                    },
                    layout: {
                        hLineColor: '#747778',
                        vLineColor: '#747778',
                    },
                    margin: [40, 20, 40, 0]
                }
            ],
            footer: (currentPage, pageCount) => {
                return {
                    columns: [
                        {
                            image: footerImage,
                            width: 595,
                            margin: [0, 0, 0, 0],
                            alignment: 'center',
                        },
                    ],
                    margin: [0, 30, 0, 0],
                };
                // if (currentPage === pageCount) {
                //     return {
                //         columns: [
                //             {
                //                 image: footerImage,
                //                 width: 595,
                //                 margin: [0, 0, 0, 0],
                //                 alignment: 'center',
                //             },
                //         ],
                //         margin: [0, -25, 0, 0],
                //     };
                // }
                // return null;
            },
        };

        pdfMake.createPdf(docDefinition).open();
    };

    render() {
        let contents = null;
        if (this.state.loading) {
            contents = (<CustomLoading/>);
        } else {
            const indexOfLastRecord = this.state.currentPage * this.state.itemsPerPage;
            const indexOfFirstRecord = indexOfLastRecord - this.state.itemsPerPage;
            const currentRecords = this.state.data.slice(indexOfFirstRecord, indexOfLastRecord);
            const nPages = Math.ceil(this.state.data.length / this.state.itemsPerPage);

            contents = (
                <div className="invoices-table report-table-container">
                    {this.state.data.length > 0 ? (
                        <>
                            <Table hover className="report-table">
                                <thead>
                                <tr>
                                    <th className="table-text-content">Invoice Date</th>
                                    <th className="table-text-content">Invoice No.</th>
                                    <th className="">Employees</th>
                                    <th className="">Total Premiums</th>
                                    <th className="">Total Tax</th>
                                    <th className="">Total Invoice</th>
                                    <th className="">Balance at Billing</th>
                                    <th className="">Amount Owing</th>
                                    <th className="table-text-content">Status</th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                {currentRecords.map((inv, index) => (
                                    <tr key={index}>
                                        <td className="table-text-content">{inv.invoiceDate}</td>
                                        <td className="table-text-content">{inv.invoiceNo}</td>
                                        <td className="">{inv.totalCertificates}</td>
                                        <td className="">${inv.invoiceAmount}</td>
                                        <td className="">${inv.taxAmount}</td>
                                        <td className="">${inv.totalInvoiceAmount}</td>
                                        <td className="">${inv.balanceAtBilling}</td>
                                        <td className="">
                                            {inv.isApproved ? (
                                                "Paid"
                                            ) : (
                                                `$${Math.max(0, (inv.totalInvoiceAmount - inv.balanceAtBilling)).toFixed(2)}`
                                            )}
                                        </td>
                                        <td className="table-text-content">{inv.status}</td>
                                        <td>
                                            <button className={"btn btn-outline-primary btn-sm"}
                                                    onClick={() => this.downloadInvoice(inv)}>Download
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                            <div className="pagination-container">
                                <div className="pagination-info">
                                    <span>Showing {indexOfFirstRecord + 1} - {indexOfFirstRecord + currentRecords.length} entries out of {this.state.data.length} entries</span>
                                </div>
                                <CustomPagination
                                    nPages={nPages}
                                    currentPage={this.state.currentPage}
                                    setCurrentPage={this.setCurrentPage}
                                />
                            </div>
                        </>
                    ) : (
                        <EmptyStateTemplate/>
                    )}
                </div>
            );
        }
        return (<>{contents}</>);
    }

}