import React from "react";
import { KTMScreen, ActionEvent, RenderEvent, renderScreen } from "ktm-ui";
import { KTMApi } from "api/KTMApi";
import {
  Layout,
  Dialog,
  Button,
  Column,
  Panel,
  Display,
  Line,
  Card,
} from "ktm-ui";
import { CustomDropdown } from "components/CustomDropdown";
import toastr from "toastr";
import CustomToolTip from "components/CustomToolTip";
import { Modal, Button as ReactButton } from "react-bootstrap";
import moment from "moment/moment";

interface IdsBenefit {
  Id: string;
  Policy_Id: string;
  Employee_Id: string;
  PolicyBenefit_Id: string;
  BenefitCategory_Id: string;
  Coverage: string;
  HasProrating: boolean;
  OverrideLimitsFlag: boolean;
  HSALimitOverride: number;
  LSALimitOverride: number;
  FlexLimitOverride: number;
  BenefitYear_Id: string;
  SalaryMoney: number;
  FlexLaunchDate: string;
  FlexReminderDate: string;
  FlexLastDayReminderDate: string;
  FlexSelectionDeadline: string;
}

interface IdsEmployee {
  Id: string;
  Name: string;
  HireDate: string;
  WaitingPeriodMonths: number;
  BenefitStartDate: string;
  IsStartDateAdjusted: boolean;
}

interface IdsBenefitYear {
  Id: string;
  StartDate: string;
}

interface IdsPolicyBenefitLimits {
  IsFlex: boolean;
}

const coverageOptions = ['SINGLE', 'COUPLE', 'FAMILY'];

export default KTMScreen(
  { name: "enroll.employee-benefits.edit" },
  class extends React.Component<{
    employeeBenefit_id: string;
  }> {
    data: {
      dsBenefit: IdsBenefit;
      dsEmployee: IdsEmployee;
      dsBenefitYear: IdsBenefitYear;
      dsPolicyBenefitLimits: IdsPolicyBenefitLimits;
    };

    state = {
      resetPAapprovedDate: false,
      showStartDateInput: false,
      showStartDateModal: false,
      isPreviousCatFlex: false,
      isFlex: false,
      options: [],
      selectedOption: null,
      creditCalcMethod: null,
      isEligible: false,
      selectedCoverage: null,
      showCampaignWarning: false,
      showBenCatUpdateModal: false,
      showCoverageUpdateModal: false,
    };

    render() {
      return renderScreen(this);
    }

    async start({ screen }: ActionEvent) {
      screen.defaultRecord = "@dsBenefit";
      await KTMApi.start(screen, {
        "@employeeBenefit_id": this.props.employeeBenefit_id,
      });
      screen.title = "Edit employee benefit limits";
      
      await this.getBenefitCategories();
      await this.checkCampaignStatus();
      await screen.triggerAction(this.set_override_visibility);
    }

    main({ field, draw, action }: RenderEvent) {
      return (
        <Layout>
          {draw(this.header)}
          {draw(this.main_tab)}
          {draw(this.footer)}
          {this.state.showBenCatUpdateModal && this.renderBenCatUpdateModal()}
          {this.state.showCoverageUpdateModal && this.renderCoverageUpdateModal()}
        </Layout>
      );
    }

    header({ field, draw, action }: RenderEvent) {
      return <Dialog.Header label={"Edit Category"} />;
    }

    footer({ field, draw, action }: RenderEvent) {
      var disableSave = this.data.dsBenefit.BenefitCategory_Id == null || 
                    this.data.dsBenefit.BenefitCategory_Id == undefined ||
                    this.state.isEligible == false;

      return (
        <Dialog.Footer>
          <Button
            action={action(this.submit)}
            label="Save"
            size="large"
            style="primary"
            disable={disableSave}
          />
        </Dialog.Footer>
      );
    }

    main_tab({ field, draw, action }: RenderEvent) {
      return (
        <Panel>
          <Column>
            {draw(this.general)}
          </Column>
        </Panel>
      );
    }

    general({ field, draw, action }: RenderEvent) {
      var flex = <></>;

      if (!this.state.isPreviousCatFlex && this.state.isFlex) {
        flex = (<Card>
          <Line>
            <Display field={field("@dsBenefit.FlexLaunchDate")} required/>
            <Display field={field("@dsBenefit.FlexReminderDate")} required/>
          </Line>
          <Line>
            <Display field={field("@dsBenefit.FlexLastDayReminderDate")} required/>
            <Display field={field("@dsBenefit.FlexSelectionDeadline")} required/>
          </Line>
        </Card>);
      }
      var limits = <Line>No Limits to Display</Line>;
      if (field("@dsBenefit.Coverage").record.Coverage == "FAMILY") {
        limits = (
          <Line>
            <Display field={field("@dsPolicyBenefitLimits.HSAFamilyLimit")} />
            <Display field={field("@dsPolicyBenefitLimits.LSAFamilyLimit")} />
          </Line>
        );
      } else if (field("@dsBenefit.Coverage").record.Coverage == "SINGLE") {
        limits = (
          <Line>
            <Display field={field("@dsPolicyBenefitLimits.HSASingleLimit")} />
            <Display field={field("@dsPolicyBenefitLimits.LSASingleLimit")} />
          </Line>
        );
      } else if (field("@dsBenefit.Coverage").record.Coverage == "COUPLE") {
        limits = (
          <Line>
            <Display field={field("@dsPolicyBenefitLimits.HSACoupleLimit")} />
            <Display field={field("@dsPolicyBenefitLimits.LSACoupleLimit")} />
          </Line>
        );
      }
      return (
        <div>
          <Card>
            <Line>
              <div className="employee-edit-bencat" style={{width:"250px"}}>
                <div className="RT-TextBox edit-employee-ben-cat">
                  <label className="RT-Label">
                    <span className="text-danger">*</span>
                    Benefit Category
                    <CustomToolTip
                      header="Benefit Category"
                      bodyContent="Changes to Benefit Category update employee benefits immediately. Per policy, edits that would result in a decreased benefit limit mid-year are discouraged."
                    />
                  </label>
                  <CustomDropdown 
                    options={this.state.options.map((option) => {return option.name})} 
                    selectedOption={this.state.selectedOption ? this.state.selectedOption.name : ''} 
                    setSelectedOption={this.setSelectedOption} 
                  />
                </div>
              </div>
              <div className="employee-edit-bencat" style={{width:"200px"}}>
                <div className="RT-TextBox edit-employee-ben-cat">
                  <label className="RT-Label">
                    Coverage
                    <CustomToolTip
                      header="Coverage"
                      bodyContent="Changes to Coverage update employee benefits immediately. Per policy, edits that would result in a decreased benefit limit mid-year are discouraged. Coverage selection is only available in OPS View. When updating Coverage, please ensure that all relevant dependents have also been hidden or unhidden."
                    />
                  </label>
                  <CustomDropdown 
                    options={coverageOptions} 
                    selectedOption={this.state.selectedCoverage || this.data.dsBenefit.Coverage} 
                    setSelectedOption={this.setSelectedCoverageOption} 
                  />
                </div>
              </div>
            </Line>
          </Card>
          {this.state.creditCalcMethod === 'SALARY' ? draw(this.salaryInput) : null}
          <Card label="Limit">{limits}</Card>
          {flex}
        </div>
      );
    }

    salaryInput({ field, draw, action }: RenderEvent) {
      return (
        <Card>
          <Line>
            <Display field={field("SalaryMoney")} />
          </Line> 
        </Card>
      );
    }

    limit_overrides({ field, draw, action }: RenderEvent) {
      return (
        <Card label="Adjusting Entry">
          <Display field={field("OverrideLimitsFlag")} />
          <Line>
            <Display field={field("HSALimitOverride")} />
            <Display field={field("LSALimitOverride")} />
            <Display field={field("FlexLimitOverride")} />
          </Line>
        </Card>
      );
    }

    toggleStartDateInput = () => {
      this.setState({ showStartDateInput: !this.state.showStartDateInput });
      this.data.dsEmployee.IsStartDateAdjusted = !this.state.showStartDateInput;
    };

    closeStartDateModal = () => {
      this.setState({ showStartDateModal: false });
    };

    setSelectedCoverageOption = async (selectedCoverage: any) => {
      this.setState({
        selectedCoverage: selectedCoverage,
        showCoverageUpdateModal: true
      });

      this.data.dsBenefit.Coverage = selectedCoverage;
    }

    setSelectedOption = async (selectedOption: any) => {
      var findOption = this.state.options.find((option) => option.name == selectedOption);
      this.setState({ 
        selectedOption: findOption,
        showBenCatUpdateModal: this.state.showCampaignWarning
      });
      this.data.dsBenefit.BenefitCategory_Id = findOption.id;
      await this.checkBenCatType();
    };

    async getBenefitCategories() {
      let response = await fetch("/api/Employees/getbenefitcategoriesforedit?policyId=" + this.data.dsBenefit.Policy_Id + "&currentBenCatId=" + this.data.dsBenefit.BenefitCategory_Id);
      let res = await response.json();
      var options = [{Id: null, name: "--Select--"}, ...res];
      var selectedOption = options.find((option) => option.id == this.data.dsBenefit.BenefitCategory_Id);
      this.setState({ 
        options: options,
        selectedOption: selectedOption,
        isPreviousCatFlex: this.data.dsPolicyBenefitLimits.IsFlex
      });
      await this.checkBenCatType();
    }

    async checkBenCatType() {
      if (this.data.dsBenefit.BenefitCategory_Id == null || this.data.dsBenefit.BenefitCategory_Id == undefined){
        this.setState({
          isFlex: false,
          creditCalcMethod: null
        });
        return;
      }

      let response = await fetch("/api/Employees/checkbencattype?policyId=" + this.data.dsBenefit.Policy_Id+"&bencatId="+this.data.dsBenefit.BenefitCategory_Id+"&employeeId="+ this.data.dsBenefit.Employee_Id);
      let res = await response.json();
      if(res.success){
        this.setState({
          isFlex: res.benCat.isFlex,
          creditCalcMethod : res.benCat.creditCalcMethod,
          isEligible: true
        });
      } 
      else {
        toastr.error(res.message);
        this.setState({
          isFlex: false,
          creditCalcMethod: null,
          isEligible: false
        });
      }
    }

    async checkCampaignStatus() {
      let response = await fetch("/api/Utility/checkcampaignstatus?policyId=" + this.data.dsBenefit.Policy_Id);
      let res = await response.json();
      if (res.isCampaignOpen) {
        this.setState({ showCampaignWarning: true });
      }
    }

    async submit({ screen }: ActionEvent) {
      if(this.data.dsBenefit.SalaryMoney != null && this.data.dsBenefit.SalaryMoney != undefined){
        this.data.dsBenefit.SalaryMoney = Math.round(this.data.dsBenefit.SalaryMoney);
      }

      if (!this.state.isPreviousCatFlex && this.state.isFlex) {
        const date = new Date();
        date.setDate(date.getDate() + 1);
        const futureDate = moment(date).format("YYYY-MM-DD");

        const {
          FlexLaunchDate,
          FlexReminderDate,
          FlexLastDayReminderDate,
          FlexSelectionDeadline,
        } = this.data.dsBenefit;

        if (FlexLaunchDate === null || FlexReminderDate === null || 
            FlexLastDayReminderDate === null || FlexSelectionDeadline === null) {
          toastr.error("Flex dates are required.");
          return;
        }
        
        if (moment(FlexLaunchDate).isBefore(futureDate)) {
          toastr.error("Flex Launch Date should be greater than today's date");
          return;
        }
        if (moment(FlexLaunchDate).isAfter(FlexReminderDate)) {
          toastr.error("Flex Launch Date should be less than Flex Reminder Date");
          return;
        }
        if (moment(FlexLaunchDate).isAfter(FlexLastDayReminderDate)) {
          toastr.error("Flex Launch Date should be less than Flex Last Day Reminder Date");
          return;
        }
        if (moment(FlexLaunchDate).isAfter(FlexSelectionDeadline)) {
          toastr.error("Flex Launch Date should be less than Flex Selection Deadline");
          return;
        }
        if (moment(FlexReminderDate).isSame(FlexLaunchDate)) {
          toastr.error("Flex Reminder Date should not be same as Flex Launch Date");
          return;
        }
        if (moment(FlexReminderDate).isAfter(FlexLastDayReminderDate)) {
          toastr.error("Flex Reminder Date should be less than Flex Last Day Reminder Date");
          return;
        }
        if (moment(FlexReminderDate).isAfter(FlexSelectionDeadline)) {
          toastr.error("Flex Reminder Date should be less than Flex Selection Deadline");
          return;
        }
        if (moment(FlexReminderDate).isSame(FlexLastDayReminderDate)) {
          toastr.error("Flex Reminder Date should not be same as Flex Last Day Reminder Date");
          return;
        }
        if (moment(FlexLastDayReminderDate).isBefore(FlexLaunchDate)) {
          toastr.error("Flex Last Day Reminder Date should be greater than Flex Launch Date");
          return;
        }
        if (moment(FlexLastDayReminderDate).isSame(FlexLaunchDate)) {
          toastr.error("Flex Last Day Reminder Date should not be same as Flex Launch Date");
          return;
        }
        if (moment(FlexLastDayReminderDate).isAfter(FlexSelectionDeadline)) {
          toastr.error("Flex Last Day Reminder Date should be less than Flex Selection Deadline");
          return;
        }
      }

      await KTMApi.action(screen, "submit", {
        "@dsBenefit.BenefitCategory_Id": this.data.dsBenefit.BenefitCategory_Id,
        "@dsBenefit.Coverage": this.data.dsBenefit.Coverage,
        "@dsBenefit.HasProrating": this.data.dsBenefit.HasProrating,
        "@dsBenefit.OverrideLimitsFlag": this.data.dsBenefit.OverrideLimitsFlag,
        "@dsBenefit.HSALimitOverride": this.data.dsBenefit.HSALimitOverride,
        "@dsBenefit.LSALimitOverride": this.data.dsBenefit.LSALimitOverride,
        "@dsBenefit.FlexLimitOverride": this.data.dsBenefit.FlexLimitOverride,
        "@employeeBenefit_id": this.props.employeeBenefit_id,
        "@dsEmployee.SalaryMoney": this.data.dsBenefit.SalaryMoney,
        "@dsEmployee.FlexLaunchDate": this.data.dsBenefit.FlexLaunchDate,
        "@dsEmployee.FlexSelectionDeadline": this.data.dsBenefit.FlexSelectionDeadline,
        "@dsEmployee.FlexReminderDate": this.data.dsBenefit.FlexReminderDate,
        "@dsEmployee.FlexLastDayReminderDate": this.data.dsBenefit.FlexLastDayReminderDate,
      });
      
      screen.close({ continue: true });
    }

    async set_override_visibility({ screen }: ActionEvent) {
      screen.hide("@dsBenefit.HSALimitOverride");
      screen.hide("@dsBenefit.LSALimitOverride");
      screen.hide("@dsBenefit.FlexLimitOverride");
      if (screen.getValue("@dsBenefit.OverrideLimitsFlag")) {
        screen.unhide("@dsBenefit.HSALimitOverride");
        screen.unhide("@dsBenefit.LSALimitOverride");
        screen.unhide("@dsBenefit.FlexLimitOverride");
      }
    }

    renderBenCatUpdateModal() {
      return (
        <Modal show={this.state.showBenCatUpdateModal} style={{ top: 250, zIndex: 10000 }} centered>
          <Modal.Header>
            <Modal.Title style={{ fontSize: 17, fontWeight: 500 }}>
              Acknowledgment
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 400, overflowY: "auto", color: "red" }}>
            <Card>
              <Line>
                <span style={{ color: "red", fontSize: 14, fontWeight: "bold" }}>
                  Updating this employee’s benefit category in their current year
                  does not automatically update their year-end Flex Campaign. To
                  apply to the next benefit year, please proceed to the Campaign tab
                  and additionally update the employee there.
                </span>
              </Line>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton variant="primary" onClick={() => this.setState({ showBenCatUpdateModal: false })}>
              Ok
            </ReactButton>
          </Modal.Footer>
        </Modal>
      );
    }

    renderCoverageUpdateModal() {
      return (
        <Modal show={this.state.showCoverageUpdateModal} style={{ top: 250, zIndex: 10000 }} centered>
          <Modal.Header>
            <Modal.Title style={{ fontSize: 17, fontWeight: 500 }}>
              Acknowledgment
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: 400, overflowY: "auto", color: "red" }}>
            <Card>
              <Line>
                <span style={{ color: "red", fontSize: 14, fontWeight: "bold" }}>
                  Edits to Coverage update the employee's current benefits immediately. 
                  In order for the edit to also apply to the next benefit year, 
                  please ensure that all relevant dependents have been hidden or unhidden, 
                  as the system will renew benefits based on eligible employees.
                </span>
              </Line>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <ReactButton variant="primary" onClick={() => this.setState({ showCoverageUpdateModal: false })}>
              Ok
            </ReactButton>
          </Modal.Footer>
        </Modal>
      );
    }
  }
);
